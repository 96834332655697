import React from "react"
import styled from "styled-components"
import Topbar from "./topbar"
import Footer from "./footer"
import { WPProvider } from "../contexts/wpContext"
import HighlightsList from "./highlightsList"

const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  padding: 0 50px;
  display: flex;
  margin: 0 auto 0 auto;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  @media (max-width: 700px) {
    padding: 0 15px;
  }
`

const HighlightsContainer = styled.div`
  width: 200px;
  height: fit-content;
  border: 1px solid #294973;
  position: sticky;
  top: 110px;
  right: 50px;
  margin: 60px 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 20px;

  @media (max-width: 1000px) {
    position: static;
    width: 100%;
    margin: 60px 0 0 0;
    align-items: flex-start;
  }

  @media (max-width: 700px) {
    margin: 40px 0 0 0;
  }
`

const HighlightsArticleGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 15px 0 0 0;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    margin: 10px 0 0 0;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
  }
`

const HighlightsTitle = styled.h5`
  font-size: 18px;
  font-family: "MinionPro";
  color: black;
  font-weight: 400;
  margin: 0;
`

const Layout = ({ children }) => {
  return (
    <WPProvider>
      <Topbar />
      <Container>
        {children}
        <HighlightsContainer>
          <HighlightsTitle>HIGHLIGHTS</HighlightsTitle>
          <HighlightsArticleGrid>
            <HighlightsList />
          </HighlightsArticleGrid>
        </HighlightsContainer>
      </Container>
      <Footer />
    </WPProvider>
  )
}

export default Layout
