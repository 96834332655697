import React from "react"

import Header from "./header"
import Navbar from "./navbar"

const Topbar = () => {
  return (
    <>
      <Header />
      <Navbar />
    </>
  )
}

export default Topbar
