import React, { createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { convertPostToOldFormat } from "../lib/wpGraphQLQueryAdapter"

export const WPContext = createContext()

export function WPProvider(props) {
  const data = useStaticQuery(graphql`
  {
    allWpPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            node {
              title
              sourceUrl
            }
          }
          author {
            node {
              name
            }
          }
          date
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }  
  `)

  // to insulate changes in graphql queries from the rest of the codebase,
  // we write an adaptor to convert to the old format for categories, authors, 
  // images, etc. this allows us to avoid re-writing the legacy code
  //
  // old format:
  // ```
  // featured_media {
  //   source_url
  //   title
  // }
  // author {
  //   name
  // }
  // date
  // categories {
  //   name
  // }
  // ```
  // new format:
  // ```
  // featuredImage {
  //   node {
  //     title
  //     sourceUrl
  //   }
  // }
  // author {
  //   node {
  //     name
  //   }
  // }
  // date
  // categories {
  //   nodes {
  //     name
  //   }
  // }
  // ```


  

  const posts = data.allWpPost.edges.map(convertPostToOldFormat);
  const getFirstCategory = post => post.node.categories[0];
  const getCategoryName = post => getFirstCategory(post).name;
  const makeIsCategoryPostChecker = category => post =>
    getCategoryName(post) === category;


  const skuleArticles = posts.filter(makeIsCategoryPostChecker("Skule™"))
  const opinionArticles = posts.filter(makeIsCategoryPostChecker("Opinions"))
  const studentLifeArticles = posts.filter(makeIsCategoryPostChecker("Student Life"))
  const galleryArticles = posts.filter(makeIsCategoryPostChecker("Gallery"))


  return (
    <WPContext.Provider
      value={{
        skuleArticles,
        opinionArticles,
        studentLifeArticles,
        galleryArticles,
      }}
    >
      {props.children}
    </WPContext.Provider>
  )
}
