import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

const SearchContainer = styled.div`
  width: 350px;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid black;
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 70px;
  display: ${props => (props.active ? "flex" : "none")};
  backdrop-filter: blur(3px);
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;

  @media (max-width: 700px) {
    display: none;
    right: 5%;
    width: 90%;
    top: 120px;
    position: fixed;
  }
`

const SearchTitle = styled.p`
  font-family: "franklinGothic";
  font-size: 18px;
  color: black;
  margin: 0 0 15px 0;
`

const SearchInput = styled.input`
  width: 100%;
  border: 1px solid black;
  background: white;
  height: 30px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "franklinGothic";
  font-size: 16px;
  color: black;
  margin: 0 0 10px 0;
`

const SearchButton = styled.button`
  box-sizing: border-box;
  height: 30px;
  width: 100px;
  display: grid;
  place-items: center;
  font-family: "franklinGothic";
  font-size: 16px;
  color: black;
  background: white;
  border: 1px solid #294973;
`

const SearchModal = ({ active, toggleActive }) => {
  const [searchText, setSearchText] = useState("")
  const handleSearchText = e => {
    setSearchText(e.target.value)
  }
  const doSearch = () => {
    if (searchText !== "") {
      navigate("/search", { state: { entry: searchText } })
      toggleActive()
    }
  }
  return (
    <SearchContainer active={active}>
      <SearchTitle>Search for articles</SearchTitle>
      <SearchInput
        type="text"
        placeholder="Search..."
        onChange={handleSearchText}
      ></SearchInput>
      <SearchButton onClick={doSearch}>Submit</SearchButton>
    </SearchContainer>
  )
}

export default SearchModal
