import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ig from "../images/ig.png"
import facebook from "../images/facebook.png"
import cannonLogo from "../images/cannonLogo.png"

const FooterMobileContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  height: fit-content;
  margin: 50px auto 0 auto;
  background: #294973;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;

  @media (min-width: 700px) {
    display: none;
  }
`

const FooterLogoImg = styled.img`
  width: 35px;
  margin: 20px 0 20px 0;
`

const FooterTitle = styled.h6`
  font-size: 24px;
  color: white;
  margin: 0 0 20px 0;
  font-family: "ImprintMTShadow";
  font-weight: 400;
`

const FooterCopyright = styled.p`
  font-size: 14px;
  margin: 20px 0 20px 0;
  font-family: "franklinGothic";
  color: white;
  text-align: center;
`

const FooterSocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 15px 0;
`

const FooterSocialLinkWrapper = styled.a`
  width: 20px;
  text-decoration: none;
  margin: 0 10px 0 10px;
`

const FooterSocialImg = styled.img`
  width: 100%;
`

const FooterLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const FooterLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: "franklinGothic";
  margin: 0 10px 0 10px;
`

const FooterMobile = () => {
  return (
    <FooterMobileContainer>
      <FooterLogoImg src={cannonLogo}></FooterLogoImg>
      <FooterTitle>THE CANNON</FooterTitle>
      <FooterSocialWrapper>
        <FooterSocialLinkWrapper
          href="https://www.instagram.com/cannon.news/?hl=en"
          target="_blank"
        >
          <FooterSocialImg src={ig}></FooterSocialImg>
        </FooterSocialLinkWrapper>
        <FooterSocialLinkWrapper
          href="https://www.facebook.com/cannon.news/"
          target="_blank"
        >
          <FooterSocialImg src={facebook}></FooterSocialImg>
        </FooterSocialLinkWrapper>
      </FooterSocialWrapper>
      <FooterLinkWrapper>
        <FooterLink to="/about">Contact</FooterLink>
        <FooterLink to="/about">Past Issues</FooterLink>
        <FooterLink>SKULE™</FooterLink>
      </FooterLinkWrapper>
      <FooterCopyright>
        © 2020 The Canon Newspaper. All rights reserved.
      </FooterCopyright>
    </FooterMobileContainer>
  )
}

export default FooterMobile
