import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ig from "../images/ig.png"
import facebook from "../images/facebook.png"
import cannonLogo from "../images/cannonLogo.png"

import FooterMobile from "./footer-mobile"

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  height: 200px;
  margin: 65px auto 0 auto;
  background: #294973;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px;

  @media (max-width: 700px) {
    display: none;
  }
`

const FooterSideWrapper = styled.div`
  width: 225px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 950px) {
    width: 175px;
  }
`

const FooterSideWrapperLeft = styled(FooterSideWrapper)`
  justify-content: flex-start;
`

const FooterSideWrapperRight = styled(FooterSideWrapper)`
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`

const FooterLogoImg = styled.img`
  width: 80px;

  @media (max-width: 950px) {
    width: 50px;
  }
`

const FooterTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`

const FooterTitle = styled.h6`
  font-size: 48px;
  color: white;
  margin: 0 0 10px 0;
  font-family: "ImprintMTShadow";
  font-weight: 400;

  @media (max-width: 950px) {
    font-size: 36px;
  }

  @media (max-width: 850px) {
    font-size: 32px;
    text-align: center;
  }
`

const FooterCopyright = styled.p`
  font-size: 14px;
  margin: 0;
  font-family: "franklinGothic";
  color: white;

  @media (max-width: 950px) {
    font-size: 12px;
    text-align: center;
  }
`

const FooterSocialWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
`

const FooterSocialLinkWrapper = styled.a`
  width: 30px;
  text-decoration: none;
  margin: 0 0 0 25px;

  @media (max-width: 950px) {
    width: 20px;
    margin: 0 0 0 15px;
  }
`

const FooterSocialImg = styled.img`
  width: 100%;
`

const FooterLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const FooterLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: "franklinGothic";
  margin: 0 0 0 20px;

  @media (max-width: 950px) {
    font-size: 12px;
    margin: 0 0 0 10px;
  }

  @media (max-width: 850px) {
    margin: 0 0 5px 0;
  }
`

const FooterLinkExt = styled.a`
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-family: "franklinGothic";
  margin: 0 0 0 20px;

  @media (max-width: 950px) {
    font-size: 12px;
    margin: 0 0 0 10px;
  }

  @media (max-width: 850px) {
    margin: 0 0 5px 0;
  }
`

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterSideWrapperLeft>
          <FooterLogoImg src={cannonLogo}></FooterLogoImg>
        </FooterSideWrapperLeft>
        <FooterTitleWrapper>
          <FooterTitle>THE CANNON</FooterTitle>
          <FooterCopyright>
            © 2020 The Cannon Newspaper. All rights reserved.
          </FooterCopyright>
        </FooterTitleWrapper>
        <FooterSideWrapperRight>
          <FooterSocialWrapper>
            <FooterSocialLinkWrapper
              href="https://www.instagram.com/cannon.news/?hl=en"
              target="_blank"
            >
              <FooterSocialImg src={ig}></FooterSocialImg>
            </FooterSocialLinkWrapper>
            <FooterSocialLinkWrapper
              href="https://www.facebook.com/cannon.news/"
              target="_blank"
            >
              <FooterSocialImg src={facebook}></FooterSocialImg>
            </FooterSocialLinkWrapper>
          </FooterSocialWrapper>
          <FooterLinkWrapper>
            <FooterLink to="/about">Contact</FooterLink>
            <FooterLink to="/about">Past Issues</FooterLink>
            <FooterLinkExt href="http://skule.ca/" target="_blank">
              SKULE™
            </FooterLinkExt>
          </FooterLinkWrapper>
        </FooterSideWrapperRight>
      </FooterContainer>
      <FooterMobile />
    </>
  )
}

export default Footer
