import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { WPContext } from "../contexts/wpContext"
import { useEffect } from "react"

const HighlightArticleWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  margin: 20px 0 0 0;

  @media (max-width: 700px) {
    margin: 10px 30px 0 0;
    padding: 0 30px 0 0;
    border-right: 1px solid #c4c4c4;
  }
`

const HighlightArticleTag = styled.p`
  font-size: 14px;
  font-family: "MinionPro";
  color: #294973;
  margin: 0 0 2px 0;
`

const HighlightArticleTitle = styled.p`
  font-size: 16px;
  font-family: "franklinGothic";
  color: black;
  margin: 0;

  @media (max-width: 700px) {
    width: 200px;
  }
`

const HighlightsList = () => {
  const { skuleArticles, opinionArticles, studentLifeArticles } = useContext(
    WPContext
  )

  const [highlightsArticles, setHighlightsArticles] = useState([])

  useEffect(() => {
    const allArticles = [
      ...skuleArticles,
      ...opinionArticles,
      ...studentLifeArticles,
    ]

    const excerptNovelty = (a) => {
      const denyList = {
        "a": true, "b": true, "c": true, "d": true, "e": true, "f": true,
        "g": true, "h": true, "i": true, "j": true, "k": true, "l": true,
        "m": true, "n": true, "o": true, "p": true, "q": true, "r": true,
        "s": true, "t": true, "u": true, "v": true, "w": true, "x": true,
        "nt": true, "being": true, "were": true, "won": true, "didn": true,
        "through": true, "things": true, "https": true, "http": true,
        "y": true, "z": true, "to": true, "of": true, "and": true, "in": true,
        "the": true, "are": true, "was": true, "is": true, "be": true,
        "that": true, "have": true, "i": true, "it": true, "for": true,
        "not": true, "on": true, "with": true, "he": true, "as": true,
        "you": true, "do": true, "at": true, "this": true, "but": true,
        "his": true, "by": true, "from": true, "they": true, "we": true,
        "say": true, "her": true, "she": true, "or": true, "an": true,
        "will": true, "my": true, "one": true, "all": true, "would": true,
        "there": true, "their": true, "what": true, "so": true, "up": true,
        "out": true, "if": true, "about": true, "who": true, "get": true,
        "which": true, "go": true, "me": true, "when": true, "make": true,
        "can": true, "like": true, "time": true, "no": true, "just": true,
        "him": true, "know": true, "take": true, "people": true, "into": true,
        "year": true, "your": true, "good": true, "some": true, "could": true,
        "them": true, "see": true, "other": true, "than": true, "then": true,
        "now": true, "look": true, "only": true, "come": true, "its": true,
        "over": true, "think": true, "also": true, "back": true, "after": true,
        "use": true, "two": true, "how": true, "our": true, "work": true,
        "first": true, "well": true, "way": true, "even": true, "new": true,
        "want": true, "because": true, "any": true, "these": true, "give": true,
        "day": true, "most": true, "us": true, "had": true, "did": true,
        "myself": true, "become": true, "became": true, "more": true, "com": true,
        "srcset": true,
      };

      const countWords = (acc, val) => {

        // don't count words on denyList
        if (denyList[val]) return acc;

        // update accumulator
        acc[val] = acc[val] || 0;
        acc[val]++;

        return acc;
      };

      const extractTopicNovelty = (content) => {
        const wordCounts = content
          // crappy HTML remover
          .replace(/<[^>]+?>/g, '')
          .match(/\w+/g)
          .map(i => i.toLowerCase())
          .reduce(countWords, {});

        const topWordCounts = Object.entries(wordCounts)
          // get top word count words
          .sort((a, b) => b[1] - a[1])
          .reduce((acc, curr) => acc + curr[1], 0)

        return topWordCounts;
      }

      return extractTopicNovelty(a.excerpt);

    };
    const recency = (a) => new Date(a.date).getTime() - Date.now();

    const scoreArticle = a => ({
      novelty: excerptNovelty(a),
      score: a.slug.length * 8 + a.categories.length * 2 +
        (excerptNovelty(a) - 5) * 50 +
        Math.floor(recency(a) / 86400 / 30),
      article: a
    })

    let foundArticles = allArticles
      .map(a => a.node)
      .map(scoreArticle)
      .sort((a, b) => b.score - a.score)
      .slice(0, 5)
      .map(a => a.article)
      .map(a => ({ node: a }));

    if (foundArticles) {
      setHighlightsArticles(prev => [...foundArticles])
    }
  }, [])

  const highlightsArray = highlightsArticles.map(edge => {
    const article = edge.node
    let tempDiv = document.createElement("DIV")
    tempDiv.innerHTML = article.title
    let properTitle = tempDiv.innerText

    // TODO: article.categories[0]?.name is an ugly hack that may result
    // in undefined input; needs to be re-written to avoid or removed
    return (
      <HighlightArticleWrapper to={`/articles/${article.slug}`}>
        <HighlightArticleTag>{article.categories[0]?.name}</HighlightArticleTag>
        <HighlightArticleTitle id={`highlightTitle-${article.slug}`}>
          {properTitle}
        </HighlightArticleTitle>
      </HighlightArticleWrapper>
    )
  })

  return <>{highlightsArray}</>
}

export default HighlightsList
