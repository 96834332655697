const convertPostToOldFormat = ({
    node: {
        title: postTitle, slug, excerpt, content,
        featuredImage=null,
        author: {
            node: {
                name
            }
        },
        date,
        categories={nodes:[]},
        tags={nodes:[]},
    }
}) => {
    let featured_media__reformatted;
    if (!featuredImage) { featured_media__reformatted = null; }
    else {
        featured_media__reformatted = {
            title: featuredImage?.node.title,
            source_url: featuredImage?.node.sourceUrl
        }
    }

    const categories__reformatted = categories && categories.nodes;
    const tags__reformatted = tags && tags.nodes;

    return ({
        node: {
            title: postTitle, slug, excerpt, content,
            featured_media: featured_media__reformatted,
            author: {
                name
            },
            date,
            categories: categories__reformatted,
            tags: tags__reformatted
        }
    });
}

exports.convertPostToOldFormat = convertPostToOldFormat;