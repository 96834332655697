import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import headerLogo from "../images/headerLogo.png"

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  background-color: #294973;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px;
  margin: 0 auto 0 auto;

  @media (max-width: 700px) {
    padding: 0 15px;
    height: 60px;
  }
`

const HeaderSideWrapper = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    width: 75px;
  }

  @media (max-width: 700px) {
    width: 35px;
  }
`

const HeaderSideWrapperLeft = styled(HeaderSideWrapper)`
  justify-content: flex-start;
`

const HeaderSideWrapperRight = styled(HeaderSideWrapper)`
  justify-content: flex-end;
`

const HeaderTitle = styled(Link)`
  font-size: 72px;
  color: #fde487;
  margin: 0;
  text-decoration: none;
  font-family: "ImprintMTShadow";
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 56px;
  }

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const HeaderLogoLinkWrapper = styled(Link)`
  text-decoration: none;
  width: 85px;
  margin: 0;

  @media (max-width: 900px) {
    width: 60px;
  }
`

const HeaderLogoImg = styled.img`
  width: 100%;
`

const HeaderDateText = styled.p`
  font-size: 18px;
  color: white;
  width: 90px;
  margin: 0;
  font-family: "franklinGothic";
  font-weight: 400;

  @media (max-width: 900px) {
    font-size: 12px;
    width: 100%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

const HeaderDateTextMobile = styled.p`
  display: none;
  @media (max-width: 700px) {
    display: inherit;
    font-size: 12px;
    color: white;
    width: 30px;
    margin: 0;
    font-family: "franklinGothic";
    font-weight: 400;
  }
`

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderSideWrapperLeft>
        <HeaderLogoLinkWrapper to="/">
          <HeaderLogoImg src={headerLogo}></HeaderLogoImg>
        </HeaderLogoLinkWrapper>
      </HeaderSideWrapperLeft>
      <HeaderTitle to="/">THE CANNON</HeaderTitle>
      <HeaderSideWrapperRight>
        <HeaderDateText>SKULE™’s newspaper since 1978</HeaderDateText>
        <HeaderDateTextMobile>Since 1978</HeaderDateTextMobile>
      </HeaderSideWrapperRight>
    </HeaderContainer>
  )
}

export default Header
