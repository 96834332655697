import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import searchIcon from "../images/searchIcon.png"
import SearchModal from "./search-modal"
import SearchModalMobile from "./search-modal-mobile"

const NavbarContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 60px;
  border-bottom: 1px solid #294973;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 3;

  @media (min-width: 1500px) {
    left: calc((100% - 1500px) / 2);
  }

  @media (max-width: 700px) {
    padding: 0 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    height: 60px;
  }
`

const NavbarSideWrapper = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1150px) {
    width: 100px;
  }

  @media (max-width: 1000px) {
    width: 75px;
  }

  @media (max-width: 700px) {
    width: fit-content;
  }
`

const NavbarSideWrapperLeft = styled(NavbarSideWrapper)`
  justify-content: flex-start;

  @media (max-width: 700px) {
    display: none;
  }
`

const NavbarSideWrapperRight = styled(NavbarSideWrapper)`
  justify-content: flex-end;
`

const NavbarSearchImg = styled.img`
  width: 20px;
  margin: 0 20px 0 0;
  cursor: pointer;
  padding: 10px 0;
`

const NavbarLinkWrapper = styled.div`
  width: calc(100% - 400px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1150px) {
    width: calc(100% - 200px);
  }

  @media (max-width: 1000px) {
    width: calc(100% - 150px);
  }

  @media (max-width: 700px) {
    width: fit-content;
  }
`

const NavbarLink = styled(Link)`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-family: "franklinGothic";
  margin: 0;

  @media (max-width: 800px) {
    font-size: 16px;
  }

  @media (max-width: 700px) {
    margin: 0 30px 0 0;
    font-size: 16px;
    white-space: nowrap;
    padding: 10px 0;
  }
`

const Navbar = () => {
  const [active, setActive] = useState(false)
  const toggleActive = () => {
    setActive(prev => !prev)
  }
  return (
    <>
      <SearchModalMobile toggleActive={toggleActive} active={active} />
      <NavbarContainer>
        <SearchModal toggleActive={toggleActive} active={active} />
        <NavbarSideWrapperLeft></NavbarSideWrapperLeft>
        <NavbarLinkWrapper>
          <NavbarLink to="/skule">SKULE™</NavbarLink>
          <NavbarLink to="/student-life">STUDENT LIFE</NavbarLink>
          <NavbarLink to="/opinions">OPINIONS</NavbarLink>
          <NavbarLink to="/gallery">GALLERY</NavbarLink>
          <NavbarLink to="/about">ABOUT</NavbarLink>
        </NavbarLinkWrapper>
        <NavbarSideWrapperRight>
          <NavbarSearchImg
            onClick={toggleActive}
            src={searchIcon}
          ></NavbarSearchImg>
        </NavbarSideWrapperRight>
      </NavbarContainer>
    </>
  )
}

export default Navbar
